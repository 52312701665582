import React from "react"

import Seo from "../components/Seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>
      This page does not exist. Try using the menu to go to safe to visit pages.
    </p>
  </>
)

export default NotFoundPage
